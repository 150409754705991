import { MouseEvent, useEffect, useRef } from 'react';
import useLoading from '~/components/hooks/useLoading';

export default function useLoadingClick(): [boolean, (fn?: (e: MouseEvent) => void) => (e: MouseEvent) => void] {
  const clicked = useRef(false);
  const doubleClicked = useRef(false);
  const loading = useLoading();

  useEffect(() => {
    if (!loading) {
      clicked.current = false;
      doubleClicked.current = false;
    }
  }, [loading]);

  return [
    loading && clicked.current,
    (fn?: (e: MouseEvent) => void) => {
      return (e: MouseEvent) => {
        if (clicked.current && !doubleClicked.current) {
          doubleClicked.current = true;

          e.preventDefault();

          return;
        }

        clicked.current = true;

        fn?.(e);
      };
    },
  ] as const;
}
